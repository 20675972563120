<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card>
        <b-form>
          <b-row>
            <b-col cols="12" class="text-right">
              <div class="text-right">
                <b-button class="" variant="danger" @click="deleteCacheData">
                                    <span class="text-nowrap text-right">
                                          <feather-icon icon="XIcon" /> Xóa Cache
                                    </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BOverlay, BRow, VBTooltip, BForm,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import { isSystemAdmin } from '@/utils'
import { getUser } from '@/auth/utils'

export default {
  name: 'Index',
  components: {
    BButton,
    BCard,
    BCol,
    BOverlay,
    BRow,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      cUser: getUser(),
    }
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      deleteCache: 'cache/deleteCache',
    }),
    async deleteCacheData() {
      this.isLoading = true
      try {
        const response = await this.deleteCache()
        if (response) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xóa cache thành công',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xóa cache thất bại',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Xóa cache thất bại',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
